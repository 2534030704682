(function () {

  const videoBlocks = document.querySelectorAll('.video-block');
  if (videoBlocks) {
    loadPlayer();
    videoBlocks.forEach(video => {
      const playButton = video.querySelector('.video-block__play');
      const overlay = video.querySelector('.video-block__overlay');
      const videoLink = playButton.getAttribute('data-src');
      const blockId = video.querySelector('.video-block__youtube').getAttribute('id');
      const videoId = videoLink.slice(-11);
      overlay.style.background = `url('https://img.youtube.com/vi/${videoId}/hqdefault.jpg') 50% 50% no-repeat`;
      overlay.style.backgroundSize = 'cover';
      playButton.addEventListener('click', function (e) {
        e.preventDefault();
        initYoutubePleayerForVideoBlock(videoId, blockId);
      })
    })
  }

  function onBlockPlayerReady(event) {
    let player = event.target;
    player.playVideo();
    let iframe = player.getIframe();
    const overlay = iframe.nextElementSibling;
    overlay.classList.add('hidden');
  }

  function initYoutubePleayerForVideoBlock(videoId, blockId) {
    console.log('youtube player for video block init')
    let player = new YT.Player(blockId, {
      height: '360',
      width: '640',
      videoId: videoId,
      playerVars: {
        'rel': 0,
        'enablejsapi' : 1,
        'origin': window.location.origin
      },
      events: {
        'onReady': onBlockPlayerReady
      }
    });
  }

  function loadPlayer() {
    console.log('loading youtube api')
    if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }
})()