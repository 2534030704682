
(function () {
  const modalOpener = document.getElementById('show-subscribe');
  const modal = document.getElementById('subscribe-dialog');

  if (modalOpener && modal) {
    const close = modal.querySelector('.modal__close')
    modalOpener.addEventListener('click', function (e) {
      e.preventDefault();
      modal.showModal();
    })

    close.addEventListener('click', function (e) {
      e.preventDefault();
      modal.close();
    })
  }
})()