(function () {
  $('.logo-slider').slick({
    variableWidth: true,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
        }
      },
    ]
  });

  $('.post-grid')
      .on('destroy', function (ev, slick) {
        let carousel = $(this),
            reinit = debounce(function () {
              carousel.slick(slick.options);
              window.removeEventListener('resize', reinit);
            }, 500);

        // Assign our debounced callback to window.resize.
        window.addEventListener('resize', reinit);
      })
      .slick({
        mobileFirst: true,
        slidesToShow: 1,
        variableWidth: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: 'unslick'
          }
        ]
      });

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
          args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

})()